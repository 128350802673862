.nav-cont {
  display: flex;
  flex-direction: column;
  flex-flow: column;
  flex-wrap: nowrap;
  width: 100%;
  z-index: 999;
}

.navbar {
  background-color: var(--footer-nav-bg);
  height: 5rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: normal;
}

.nav-item {
  display: flex;
  text-transform: uppercase;
  align-content: center;
  justify-content: center;
  height: 50px;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
}

.nav-item a {
  list-style: none;
  padding-left: 1.75rem;
  cursor: pointer;
}

.nav-item a:hover {
  filter: brightness(80%);
}

.menu-icon {
  display: none;
}

.navbar .event-name {
  font-size: 30px;
  text-shadow: none;
}

.dropdown {
  display: none;
}

.dropdown-extended {
  display: none;
}

@media screen and (max-width: 1000px) {
  .navbar {
    height: 4.5rem;
  }

  .nav-item a:not(:last-child) {
    display: none;
  }
  .nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .menu-icon {
    display: block;
    padding: 0;
    margin: 0;
  }
  .navbar .event-name {
    font-size: 1.75rem;
    padding: 0;
    padding-left: 0.5rem;
  }
  .dropdown-extended {
    display: flex;
    flex-direction: column;
    flex-flow: column;
    background-color: var(--footer-nav-bg);

    align-items: flex-end;
    font-size: 1.2rem;
    padding-right: 2rem;
  }

  .dropdown-extended a {
    list-style: none;
    padding-left: 0px;
    cursor: pointer;
    padding-top: 1rem;
    text-transform: uppercase;
  }

  .dropdown-extended a:last-child {
    padding-bottom: 2rem;
  }

  .dropdown-extended a:hover {
    filter: brightness(80%);
  }
}

/* @media screen and (prefers-color-scheme: light) {
  .menu-icon {filter: invert(100%);}
} */
