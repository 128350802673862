.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 600px;
}

.input-field {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  gap: 0.25rem;
}

.login .title {
  align-self: center;
}
