.footer {
  flex-basis: 100%;
  justify-self: stretch;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex: content;
  justify-content: space-between;
  align-items: center;
  flex-flow: row;
  max-width: 100vw;
  min-height: 20vh;

  padding: 3rem 3rem 1rem 3rem;
}

.footer ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex: content;
  max-width: fit-content;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  list-style-type: none;
  gap: 0.75rem;
}

.footer .subtitle {
  align-self: center;
  padding: 0;
  padding-left: 1rem;
  border-color: none;
  border-style: none;
  font-family: "JetBrains Mono", monospace;
}

.footer a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-flow: row;
  align-items: center;
}

.icon {
  /* filter: invert(100%); */
  padding-right: 0.5rem;
}

.no-icon {
  margin-left: 0.25rem;
}

.footer a:hover {
  filter: brightness(85%);
  cursor: pointer;
}

.footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(50% + 5rem);
}

.sms-fb-logo {
  width: 10rem;
}

@media (max-width: 1000px) {
  .footer {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    padding: 3rem 1rem 1rem 1rem;
  }
  .footer ul {
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0rem;
  }
  .footer a {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0rem;
    justify-self: center;
  }
  .no-icon {
    margin-left: 0;
    align-self: flex-end;
    padding-left: 1.5rem;
  }
  .footer-wrapper {
    align-self: center;
    width: 90%;
  }
  .sms-fb-logo {
    padding-left: 0.75rem;
    width: 5.5rem;
  }
}
