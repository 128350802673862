.news-section {
  flex-basis: 100%;
  justify-self: stretch;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  max-width: 100%;
  height: auto;
  padding: calc(4rem + 0.5vmin) calc(6rem + 0.5vmin) calc(4rem + 0.5vmin)
    calc(6rem + 0.5vmin);
  background: var(--section-color-even);
}

.news-section .title:first {
  padding-bottom: 2rem;
}

.actions {
  display: flex;
  align-items: center;
  align-self: flex-end;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
}

@media screen and (max-width: 1000px) {
  .news-section {
    padding: 2rem 1.75rem 2rem 1.75rem;
    flex-direction: column;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
