.time-left {
  display: flex;
  padding: 0;
  margin: 0;
  flex-basis: 100%;
  justify-self: stretch;
  flex-direction: row;
  justify-content: center;
  column-gap: 3vmin;
}

.number-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
}

.time-left > *:first-child {
  padding-right: 3rem;
}

.number {
  font-family: "Space Grotesk", sans-serif;
  /* vertical-align:text-top; */
  font-size: 1.25rem;
  font-size: clamp(1.25rem, 0.5rem + 3.75vw, 5rem);
}

.date-text {
  font-size: 1rem;
  font-size: clamp(1rem, 0.7rem + 1.5vw, 2.5rem);
}

@media screen and (max-width: 1000px) {
  .time-left {
    padding-top: 2rem;
    column-gap: 2vmin;
  }
}
