/* :root {
  --accent: #ff0074;
  --comp-accent: #00ff8b;
  --text-color: #efefef;
  --text-color-invert: #121212;
  --footer-nav-bg: #151722;
  --section-color: #1e202e;
  --section-color-even: #272939;
  --margin: 1.4rem;
} */

:root {
  --footer-nav-bg: #242629;
  --section-color: #242629;
  --section-color-even: #16161a;
  
  --text-color: #efefef;
  --text-color-invert: #121212;

  --accent: #7f5af0;
  --comp-accent: #2cb67d;
  --border: #ffffff44;
}

@font-face {
  font-family: JetBrains Mono;
  src: local("JetBrains Mono"),
    url("./assets/fonts/JetBrainsMono-VariableFont.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Archivo;
  src: local("Archivo"),
    url("./assets/fonts/Archivo-VariableFont.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Archivo Italic;
  src: local("Archivo Italic"),
    url("./assets/fonts/Archivo-Italic-VariableFont.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Space Grotesk;
  src: local("Space Grotesk"),
    url("./assets/fonts/SpaceGrotesk-VariableFont.ttf") format("truetype");
  font-display: swap;
}

* {
  margin: 0;
}

html {
  overflow-x: hidden;
  color: var(--text-color);
  background-color: var(--footer-nav-bg);
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Archivo, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: JetBrains Mono, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

input[type="email"],
input[type="password"],
input[type="datetime-local"],
input[type="text"],
textarea {
  border-color: var(--accent);
  border-style: solid;
  border-radius: 0.25rem;
  border-width: 0.1rem;
  min-width: 60%;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #ffffff00;
  color: var(--text-color);
  font-family: "JetBrains Mono";
}

input:focus,
textarea:focus {
  background-color: #ffffff10;
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px var(--footer-nav-bg) inset !important;
}

label {
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

.page-enter {
  opacity: 0;
  transform: translateY(2rem);
}
.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms ease, transform 500ms ease;
  transition-delay: 150ms;
}
.page-exit {
  opacity: 1;
  transform: translate(0);
}
.page-exit-active {
  opacity: 0;
  transform: translateY(2rem);
  transition: opacity 200ms ease, transform 200ms ease;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}