.section {
  flex-basis: 100%;
  justify-self: stretch;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  max-width: 100%;
  height: auto;
  padding: calc(4rem + 0.5vmin) calc(6rem + 0.5vmin) calc(4rem + 0.5vmin)
    calc(6rem + 0.5vmin);
  background: var(--section-color-even);
}

.fill {
  min-height: 65vh;
}

.left-side {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  flex-basis: 50vw;
  /* align-self: stretch; */
  align-items: flex-start;
  margin-right: 2rem;
}

.right-side {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-left: 1rem;
  flex-basis: 50vw;
  margin-left: 2rem;
  align-self: stretch;
  justify-content: center;
}

.section:nth-of-type(even) {
  background-color: var(--section-color);
}

.title-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.5s ease-out;
  padding-bottom: 1rem;
}

#link:hover {
  cursor: pointer;
  transform: translateX(1rem);
}

.title {
  font-family: "Space Grotesk", monospace;
  font-size: 1.5rem;
  font-size: clamp(1.5rem, 1.1rem + 2vw, 3.5rem);
  align-self: flex-start;
}

.subtitle {
  font-size: 1.25rem;
  font-size: clamp(1.25rem, 1.1rem + 0.75vw, 2rem);
  align-self: flex-start;
  justify-self: center;
}

.section .subtitle {
  filter: brightness(75%);
}

.text {
  font-size: 1rem;
  font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
  align-self: flex-start;
  justify-self: center;
  font-weight: normal;
  max-width: 800px;
  line-height: 1.6;
  text-wrap: balance;
}

.carousel {
  align-self: flex-start;
  justify-self: center;
}

.section .icon {
  filter: invert(0%) brightness(100%);
  margin: 0;
  padding: 0;
  padding-left: clamp(0.5rem, 1rem + 1vw, 2rem);
  height: clamp(1.5rem, 1.1rem + 2vw, 3rem);
}

.image {
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: auto;
  border-radius: 1rem;
}

.image-alpha {
  object-fit: contain;
  width: 90%;
  height: 90%;
}

.carousel-item {
  align-self: center;
}

.carousel .image {
  /* height: 220px; */
  aspect-ratio: 1/1;
  height: 100%;
  max-height: 240px;
  max-width: 100%;
  border-radius: 4px;
  transition: all 0.5s ease;
  background: content-box var(--accent);
}

.carousel .image:hover {
  transform: translateY(0.5rem);
  transition: all 0.2s ease;
}

.arrow {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 20px;
  color: var(--text-color);
  background-color: var(--accent);
  cursor: pointer;
  top: calc(50% - 1.25rem);
  border-radius: 0.5rem;
  z-index: 2;
  opacity: 0.8;
  box-shadow: 0px 1px 6px var(--footer-nav-bg);
}

#right {
  right: 0;
}

.arrow:hover {
  opacity: 1;
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 16rem;
  align-self: stretch;
  justify-self: center;
  border-style: solid;
  border-color: var(--border);
  border-radius: 1rem;
  border-width: 0.1rem;
  /* padding: 1.5rem; */
  background: var(--section-color);
  box-shadow: 0px 0px 4.1px -55px rgba(0, 0, 0, 0.251),
    0px 0px 11.3px -55px rgba(0, 0, 0, 0.36),
    0px 0px 27.1px -55px rgba(0, 0, 0, 0.469),
    0px 0px 90px -55px rgba(0, 0, 0, 0.72);
  transition: all 0.5s ease-out;
}

.card:hover,
.card:focus {
  border-style: solid;
  border-color: var(--accent);
  background: linear-gradient(
      30deg,
      rgba(36, 38, 41, 1) 0%,
      rgba(36, 38, 41, 1) 45%,
      rgba(127, 90, 240, 1) 100%
    ),
    url("../assets/bbburst.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: soft-light;

  transition: all 0.25s ease-in-out;
}

.card-head {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 1.5rem;
  align-items: stretch;
}

.card-body {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  height: 100%;
  justify-content: end;
  padding: 1.5rem;
}

.card-head .title {
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0;
}

.card-body .text {
  max-width: 80%;
  white-space: pre-wrap;
  font-size: 1rem;
  padding-bottom: 2rem;
}

.card-head .title:hover {
  transform: none;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.centered {
  align-self: center;
  text-align: center;
}

.big-gap {
  gap: 1rem;
}

.dark {
  background-color: var(--section-color-even) !important;
}

@media screen and (max-width: 1000px) {
  .section {
    padding: 2rem 1.75rem 2rem 1.75rem;
    flex-direction: column;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .left-side {
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    padding-right: 0rem;
    padding-left: 0;
    margin: 0;
    flex-basis: 50% !important;
    align-self: stretch;
  }

  .right-side {
    display: flex;
    flex-basis: 50% !important;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    margin: 0;
  }

  .title {
    border-width: 0 0 0px 0px;
    padding-bottom: calc(0.5rem - 3px);
  }

  .title:hover {
    transform: none;
    text-shadow: none;
    box-shadow: none;
  }

  .image {
    width: 100%;
    max-height: 320px;
  }

  .image-alpha {
    max-height: 200px;
  }
}

@media screen and (max-width: 600px) {
  .card {
    flex-grow: 1;
  }
}
