.button {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* width: auto; */
  height: 1rem;
  align-items: center;
  border-color: var(--accent);
  border-style: solid;
  border-radius: 0.5rem;
  border-width: 1px;
  padding: 1.25rem 1.5rem 1.25rem 1.5rem;
  color: var(--text-color);
  font-size: 1rem;
  justify-content: center;
  font-family: "Archivo";
  font-stretch: condensed;
  transition: all 0.4s ease-out;
}

.button a {
  text-decoration: none;
  color: var(--text-color);
}

.button a:nth-child(2) {
  padding-left: 0.5rem;
}

.button:hover {
  cursor: pointer;
  background-color: #efefef22;
  transform: translateY(0.25rem);
  transform-origin: bottom left;

  box-shadow: inset 0px -2px 4px var(--accent);
  transition: all 0.2s ease-out;
}

.compact {
  padding: 1rem;
}

.hero-button {
  border-color: var(--comp-accent);
  margin-top: 2.5rem;
  background-color: var(--accent);
  font-size: 1.5rem;
  font-size: clamp(1.5rem, 1.3333333333333333rem + 0.5555555555555556vw, 2rem);
  padding: 2rem 3rem;
  padding: clamp(1.5rem, 1.3181818181818181rem + 0.5681818181818182vw, 2rem) clamp(1.5rem, 0.9545454545454546rem + 1.7045454545454544vw, 3rem);
  transition: all 0.2s ease;
}

.hero-button:hover {
  background-color: var(--accent);
  box-shadow: inset -8px 8px 12px var(--comp-accent);
}
