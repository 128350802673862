.new-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;
  width: 90%;
}

.full-width {
  width: 100%;
}

.input-field {
  display: flex;
  flex-direction: column;
  min-width: 70%;
  gap: 0.25rem;
}

.center {
  align-items: stretch;
  /* justify-content: center; */
  align-self: flex-start;
  width: 90%;
}

@media screen and (max-width: 1000px) {
  .center {
    align-self: center;
  }
}

.center .title {
  align-self: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.big {
  min-height: 4rem;
  resize: vertical;
  overflow: auto;
  line-height: 1.5rem;
}

.login .title {
  align-self: center;
}
