.hero {
  align-self: center;
  justify-self: stretch;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  min-height: 70vh;
  max-height: 70vh;
  min-height: 70svh;
  max-height: 70svh;
  background: content-box linear-gradient(0deg, var(--accent), var(--accent)),
    url("../assets/hero_bg.webp") no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: color;
}

.small-hero {
  min-height: 50vh;
  max-height: 50vh;
  min-height: 50svh;
  max-height: 50svh;
}

.error {
  padding: 15%;
  align-self: center;
  justify-self: center;
  text-align: center;
  font-size: calc(10px + 8rem);
  font-family: "JetBrains Mono", monospace;
}

.error-404 .section {
  flex-direction: column;
  flex-flow: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  text-align: center;
}

.event-name {
  align-self: center;
  justify-self: center;
  font-family: "JetBrains Mono", monospace;
  font-size: 2.5rem;
  font-size: clamp(2.5rem, 1.4rem + 5.5vw, 8rem);
  text-shadow: 4px 3px 4px rgba(0, 0, 0, 0.2);
}

.smaller {
  font-size: 2rem;
  font-size: clamp(2rem, 1.5rem + 1.6666666666666667vw, 3.5rem);
}

.hero .subtitle {
  text-align: center;
  align-self: center;
  border-color: none;
  border-style: none;
  font-stretch: extra-expanded;
}

.logo {
  padding: 10px;
  height: calc(10px + 10vmin);
}

.marquee {
  --gap: 1rem;
  --gap: clamp(1rem, 0.8rem + 1vw, 2rem);
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  background-color: var(--comp-accent);
  height: 6rem;
  align-items: center;
}

.marquee-content {
  color: var(--text-color-invert);
  font-size: 2rem;
  font-size: clamp(1.5rem, 1.6rem + 2vw, 4rem);
  font-family: "Space Grotesk", monospace;
  font-weight: 700;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
  padding: 0;
  animation: scroll 10s linear infinite;
}

.wrapper {
  width: 90%;
}

.divider {
  height: 2px;
  width: 100%;
  margin-top: 0rem;
  background-color: var(--accent);
  align-self: flex-start;
}

#mail {
  color: var(--accent);
  box-shadow: inset 0 0 0 0 #fff;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

#mail:focus,
#mail:hover {
  cursor: pointer;
  box-shadow: inset 800px 0 0 0 var(--comp-accent);
  color: var(--text-color);
}

.padding-bottom {
  padding-bottom: 2rem;
}

.Main .left-side > .subtitle {
  padding-bottom: 1rem;
}
.Main .left-side > .subtitle:empty {
  padding-bottom: 0rem;
}

.padding-right {
  padding-right: 5rem;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@media (max-width: 800px) {
  .small-hero {
    min-height: 40vh;
    max-height: 40vh;
    min-height: 40svh;
    max-height: 40svh;
  }
}

/* @media screen and (prefers-color-scheme: light) {
  .hero .event-name, .hero .subtitle {
    color: var(--text-color-invert);
  }
} */
