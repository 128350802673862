.sponsor-grid {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 2rem;
  gap: 2.5rem;
  align-items: stretch;
  justify-content: center;
  justify-self: center;
  align-self: center;
}

.sponsor-icon {
  padding: 0;

  object-fit: contain;
  max-width: 18rem;
}

.wrapper .divider {
  height: 4px;
  width: 100%;
  margin-top: 0.5rem;
  border-radius: 1rem;
  background-color: var(--accent);
  align-self: flex-start;
}

@media screen and (max-width: 1000px) {
  .sponsor-icon {
    text-align: center;
  }
}
