.gallery-grid {
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  padding: 2rem;
}

.gallery-grid .item {
  flex-grow: 1;
  height: 180px;
  max-width: 100%;
  --delay: calc(var(--item) * -200ms);
  animation: pop 1200ms var(--delay, 0) ease-in-out;
  transition: transform 0.25s ease-in-out;
}

.gallery-grid .item:hover {
  transform: translateY(-0.5rem) scale(1.01);
}

.gallery-grid .image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  filter: grayscale(10%) contrast(80%);
  vertical-align: middle;
  border-radius: 0.25rem;
}

.popup {
  display: none;
}

.popup-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.popup-image {
  width: 80vw;
  max-height: 75vh;
  max-height: 75svh;
  object-fit: contain;
  border-radius: 0.25rem;
  animation: zoom 0.3s ease-in-out;
}

@keyframes pop {
  0% {
    transform: scale(0.1);
    opacity: 0%;
  }
  100% {
    transform: scale(1);
    opacity: 100%;
  }
}

@keyframes zoom {
  0% {
    transform: scale(0.4);
    opacity: 0%;
  }
  100% {
    transform: scale(1);
    opacity: 100%;
  }
}

.gallery-grid .image:hover {
  filter: grayscale(0%);
}

@media screen and (max-width: 1000px) {
  .gallery-wrapper {
    max-width: 100%;
  }
  .gallery-grid {
    gap: 0.75rem;
    padding: 1.5rem 0.5rem 2rem 0.5rem;
    /* justify-content: center; */
  }
  .gallery-grid .item {
    height: 160px;
    width: 40%;
  }
}
