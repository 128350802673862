.news {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  align-self: center;
  justify-self: center;
  border-style: solid;
  border-color: var(--border);
  border-radius: 1rem;
  border-width: 0.1rem;
  background-color: var(--footer-nav-bg);
  box-shadow: 0px 0px 4.1px -55px rgba(0, 0, 0, 0.251),
    0px 0px 11.3px -55px rgba(0, 0, 0, 0.36),
    0px 0px 27.1px -55px rgba(0, 0, 0, 0.469),
    0px 0px 90px -55px rgba(0, 0, 0, 0.72);
}

.news:focus,
.news:hover {
  border-style: solid;
  border-color: var(--accent);
}

.head {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 1.5rem;
  align-items: stretch;
}

.news-body {
  border-radius: 0 0 1rem 1rem;
  padding: 1.5rem;
  padding-top: 1rem;
}

.date {
  align-self: flex-start;
  text-transform: lowercase;
  font-family: "JetBrains Mono", monospace;
  font-stretch: condensed;
  font-weight: 200;
  font-size: 0.75rem;
  flex-shrink: 0;
  font-size: clamp(0.75rem,
      0.44999999999999996rem + 0.6666666666666667vw,
      1.25rem);
  max-width: 35%;
  white-space: nowrap;
  padding-top: 0.75rem;
}

.head .title {
  align-self: flex-start;
  font-size: 1rem;
  font-size: clamp(1rem, 0.6rem + 2vw, 3rem);
  padding: 0;
  word-wrap: break-word;
  font-weight: 500;
}

.news-body .text {
  max-width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  hyphens: auto;
}

.head .title:hover {
  transform: none;
}

.popup {
  display: none;
}

.popup-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.image {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 35%;
  height: 100%;
  align-self: center;
  justify-self: center;
  border-radius: 0.50rem;
}

.popup-image {
  width: 80vw;
  max-height: 75vh;
  max-height: 75svh;
  object-fit: contain;
  border-radius: 0.25rem;
  animation: zoom 0.3s ease-in-out;
}