.contact {
  padding: 0;
}

.contact .left-side {
  padding: 0;
  margin: 0;
  background: content-box linear-gradient(0deg, var(--accent), var(--accent)),
    url("../assets/hero_bg.webp") no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: color;
  align-self: stretch;
}

.contact .right-side {
  margin: calc(4rem + 0.5vmin) calc(6rem + 0.5vmin) calc(4rem + 0.5vmin)
    calc(6rem + 0.5vmin);
  padding: 0;
  flex: 1;
}

.contact .subtitle {
  margin-top: -2rem;
}

.socials {
  margin-top: -1.5rem;
  margin-left: -0.5rem;
}

.contact ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex: content;
  max-width: fit-content;
  flex-direction: column;
  flex-wrap: ;
  justify-content: space-evenly;
  align-content: center;
  list-style-type: none;
  font-size: 1.25rem;
  font-size: clamp(1.25rem, 0.975rem + 0.25vw, 1.5rem);
}

#soc-links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-flow: row;
  align-items: center;
  padding: 0.5rem;
}

#soc-links .icons {
  padding-right: 0.5rem;
}

.no-icon {
  margin-left: 0.25rem;
}

#soc-links:hover {
  filter: brightness(85%);
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .contact .left-side {
    flex-basis: auto !important;
    height: 380px;
  }
  .contact .right-side {
    margin: 0;
    padding: 2rem 1.75rem 2rem 1.75rem;
  }
}
